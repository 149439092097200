<template>
  <div v-if="cache && cache.doc">
    <span :class="{
      'primary--text': innerOptions.emphase,
      'font-weight-bold': innerOptions.emphase
    }
    ">{{amount|currency(currency)}}</span>
    <span> {{$t('t.At')|lowercase}} </span>
    <span :class="{'font-weight-bold': innerOptions.emphase}">{{date|date}}</span>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  computed: {
    amount () {
      return this.cache?.doc?.amount
    },
    currency () {
      return this.cache?.doc?.currency
    },
    date () {
      return this.cache?.doc?.date
    },
    cacheType () {
      return ClientCache.CacheType.PaymentRef
    },
    innerOptions () {
      return {
        emphase: !!this.options?.emphase
      }
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    options: Object
  }
}
</script>

<style lang="stylus" scoped>
.colorZone
  width 6px
  height 12px
  border-radius 2px

.number
  font-size 12px

.businessIdentifier
  color silver
  font-size 12px
</style>
